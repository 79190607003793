import React from 'react'
import ReactDOM from 'react-dom'
import GoogleTagManager from './gtm';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

import './style.css'
import Home from './views/home'
import NotFound from './views/not-found'
const addGtagScript = () => {
  const script1 = document.createElement('script');
  script1.async = true;
  script1.src = "https://www.googletagmanager.com/gtag/js?id=G-KT1BM36HHW";
  document.head.appendChild(script1);

  const script2 = document.createElement('script');
  script2.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-KT1BM36HHW');
  `;
  document.head.appendChild(script2);
};

// Add the script when the app initializes
addGtagScript();
// const App = () => {
//   // return (
//   //   <Router>
//   //     <Switch>
//   //       <Route component={Home} exact path="/" />
//   //       <Route component={NotFound} path="**" />
//   //       <Redirect to="**" />
//   //     </Switch>
//   //   </Router>
//   // )
//   return (
//     <>
//       {/* Add GTM */}
//       <GoogleTagManager gtmId={GTM-KT73HRDF} />
//       <Router>
//         <Switch>
//           <Route component={Home} exact path="/" />
//           <Route component={NotFound} path="**" />
//           <Redirect to="**" />
//         </Switch>
//       </Router>
//     </>
//   );
// }

// ReactDOM.render(<App />, document.getElementById('app'))
const App = () => {
  const GTM_ID = 'GTM-KHRDF'; // Replace with your actual GTM ID

  return (
    <>
      {/* Add GTM */}
      <GoogleTagManager gtmId={GTM_ID} />
      <Router>
        <Switch>
          <Route component={Home} exact path="/" />
          <Route component={NotFound} path="**" />
          <Redirect to="**" />
        </Switch>
      </Router>
    </>
  );
};

ReactDOM.render(<App />, document.getElementById('app'));