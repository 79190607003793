import React from 'react'

import { Helmet } from 'react-helmet'

import StoreBanner from '../components/store-banner'
import PrimaryButton from '../components/primary-button'
import Banner1 from '../components/banner1'
import FeatureCard from '../components/feature-card'
import TestimonialsCard from '../components/testimonials-card'
import StatsCard from '../components/stats-card'
import LinkIconButton from '../components/link-icon-button'
import './home.css'


const Home = (props) => {
  return (
    <div className="home-container">
      
      <Helmet>
    <title> StoryLine - Read and Write Stories Hindi, English, Tamil, Marathi</title>
    <link rel="canonical" href="https://storyline.world/" />

    <meta property="og:title" content="        StoryLine - Read and Write Stories in Hindi, Tamil, Marathi, Gujarati, Kannada, Telugu, Bengali, Malayalam, English, Punjabi, Urdu, French, German, Korean, Spanish, Italian, and Japanese 🌍📖" />
    <meta name="keywords" content="
        short story, english small stories, english story short, short english story, short story in english, short story of english, 
        story for english, short english stories, short stories in english, a short story, short stories, short story short stories, 
        small story of english, story of adults, stories to read, story read, english story pdf, english stories pdf, pdf english story, 
        stories pdf in english, story in english pdf, english short story with moral, english stories with moral, english story with moral, 
        moral stories in english, short stories in english with moral, short stories with moral in english, short story in english with moral, 
        short story with moral in english, small story in english with moral, stories in english with moral, hindi story, story of hindi, 
        hindi story in hindi, english story, hindi kahani, hindi of story, hindi stories in hindi, story in hindi, in english story, 
        in hindi stories, in hindi story, story in english, stories for kids, story for kids, story writing, writing stories, kahani in hindi, 
        hindi kahaniya in hindi, hindi short stories in hindi, short hindi stories, short stories for kids, short stories hindi, short story hindi, 
        short story in hindi, stories in hindi short, story in hindi short, writing a story in english, stories in hindi, story story,
         read stories in Hindi, write stories in Hindi, Hindi short stories, Hindi moral stories, 
        read stories in Tamil, write stories in Tamil, Tamil short stories, Tamil moral stories, 
        read stories in Marathi, write stories in Marathi, Marathi short stories, Marathi moral stories, 
        read stories in Gujarati, write stories in Gujarati, Gujarati short stories, Gujarati moral stories, 
        read stories in Kannada, write stories in Kannada, Kannada short stories, Kannada moral stories, 
        read stories in Telugu, write stories in Telugu, Telugu short stories, Telugu moral stories, 
        read stories in Bengali, write stories in Bengali, Bengali short stories, Bengali moral stories, 
        read stories in Malayalam, write stories in Malayalam, Malayalam short stories, Malayalam moral stories, 
        read stories in English, write stories in English, English short stories, English moral stories, 
        read stories in Punjabi, write stories in Punjabi, Punjabi short stories, Punjabi moral stories, 
        read stories in Urdu, write stories in Urdu, Urdu short stories, Urdu moral stories, 
        read stories in French, write stories in French, French short stories, French moral stories, 
        read stories in German, write stories in German, German short stories, German moral stories, 
        read stories in Korean, write stories in Korean, Korean short stories, Korean moral stories, 
        read stories in Spanish, write stories in Spanish, Spanish short stories, Spanish moral stories, 
        read stories in Italian, write stories in Italian, Italian short stories, Italian moral stories, 
        read stories in Japanese, write stories in Japanese, Japanese short stories, Japanese moral stories
    " />
<meta name="description" content="Read and write stories in 17 languages, including Hindi, English, Tamil, and more. Explore short and moral stories!" />
<link rel="icon" type="image/svg+xml" href="/33.svg" />
      </Helmet>

      <div data-role="Header" className="home-header-container">
  <header className="home-header">
    <div className="home-container01">
      <div className="home-logo">
        <img 
          alt="StoryLine Mobile app" 
          title="StoryLine - Read and Write Stories" 
          src="/21.svg" 
          className="home-image" 
        />
      </div>
    </div>
    <div className="home-container02">
      <StoreBanner></StoreBanner>
    </div>
  </header>
  <div data-role="MobileMenu" className="home-mobile-menu">
    <div className="home-top">
      <div className="home-logo1">
        <img
          alt="StoryLine Logo"
          title='StoryLine - Read and Write Stories'
          src="/logotype-dark.svg"
          className="home-image1"
        />
      </div>
    </div>
  </div>
</div>
      <div className="home-main">
      <meta name="title" content="StoryLine - Read Write Stories in Hindi, English, Tamil, Marathi, Gujarati, Kannada, Telugu, Bengali, Malayalam, Punjabi, Urdu, French, German, Korean, Spanish, Italian, and Japanese" />
      <meta name="description" content="StoryLine is a platform where you can read and write stories in multiple languages. Discover our collection of short and moral stories, and get inspired to create your own." />
        <div className="home-blur-background"></div>
        <div className="home-hero">
          <div className="home-container08">
            <h1 className="home-text06 Headline1">
              {/* <span> */}
                StoryLine
              {/* </span> */}
              <br></br>
              <h6>Read Write Stories.</h6>
            </h1>
            <PrimaryButton button="Download Now"></PrimaryButton>
          </div>
          <img
          alt="StoryLine App - Read and Write Stories in Multiple Languages"
          title='Storyline - Read & write Stories in Hindi, English, Tamil, Marathi, Gujarati, Kannada, Telugu, Bengali, Malayalam, Punjabi, Urdu, French, German, Korean, Spanish, Italian, and Japanese'
          src="/iphonex-1200w.png" className="home-image2"   loading="lazy"

          />
        </div>
        <Banner1 rootClassName="banner1-root-class-name"></Banner1>
        <img
          title="sad story"
          alt="StoryLine App - Read and Write Stories in Multiple Languages,odia story,sad story,short story short stories,short tales in english,motivational short stories in hindi,friendship moral stories in english"
          src="/turquoise-circle.svg"
          className="home-turquoise-cirble"
        />
        <img
          title="odia story"
          alt="StoryLine App - Read and Write Stories in Multiple Languages,chudail ki kahani,bhoot wali kahani,bacchon ki kahani,love story in hindi,forbidden love,fun stories"
          src="/purple-circle.svg"
          className="home-purple-circle"
        />
        <img alt="image" src="/left.svg" className="home-left" />
        <img alt="image" src="/right.svg" className="home-right" />
      </div>
      <div className="home-features">
        <h2 className="Headline2 home-text10">App features</h2>
        <span className="home-text11">
    Discover StoryLine, the ultimate app for story enthusiasts! Whether you're looking for story writing apps, story reading apps, simple english story, short story in hindi, kahani in hindi, small story in english or bedtime stories to read online for free, we have it all. Explore bedtime stories for preschoolers, small bedtime stories, short stories with pictures, and more.📖✨
      </span>

      <meta name="keywords" content="
          story writing apps, story reading app, bedtime stories to read online free, bedtime story short, reading story books, story book with pictures, bedtime stories for preschoolers, bedtime story short story, small bedtime stories to read,wattpad app " />
      <meta name="description" content="
          StoryLine offers an extensive collection of short stories, simple english story,bedtime stories, storybooks with pictures, and tools for story writing and reading. Perfect for all ages, especially preschoolers and those seeking short bedtime tales.
      " />
      <meta name="author" content="StoryLine App" />
      <meta property="og:title" content="StoryLine - Explore Story Writing and Reading Apps" />
      <meta property="og:description" content="
          StoryLine is your go-to app for writing, reading, and enjoying short stories. Dive into bedtime stories, storybooks with pictures, and tales perfect for preschoolers or bedtime reading.
      " />
        <div className="home-features1">
  <FeatureCard
    imageSrc="/01.svg"
    cardTitle="Multilingual Stories"
    text="Immerse yourself in a diverse collection of short stories available in multiple languages, including Hindi, Tamil, Marathi, Gujarati, Kannada, Telugu, Bengali, Malayalam, English, Punjabi, Urdu, French, German, Korean, Spanish, Italian, and Japanese. Perfect for story writing and story reading enthusiasts."
  ></FeatureCard>

  <FeatureCard
  imageAlt="Explore a wide range of genres like romance, mystery, fantasy, adventure, and bedtime stories to read online free. Whether you're into short stories or long narratives, we've got you covered."
    imageSrc="/02.svg"
    cardTitle="Multiple Categories"
    text="Explore a wide range of genres like romance, mystery, fantasy, adventure, and bedtime stories to read online free. Whether you're into short stories or long narratives, we've got you covered."
  ></FeatureCard>

  <FeatureCard
    imageAlt="Share your own stories or dive into tales written by others. Save your favorite stories and resume reading them anytime with our seamless story reading app features."

    imageSrc="/04.svg"
    cardTitle="User-Generated Content"
    text="Share your own stories or dive into tales written by others. Save your favorite stories and resume reading them anytime with our seamless story reading app features."
  ></FeatureCard>

  <FeatureCard
  imageAlt="Enjoy fresh content every day with our regularly updated collection of short stories, bedtime stories for preschoolers, and small bedtime stories. You’ll never run out of new and engaging tales to read!"
    imageSrc="/05.svg"
    cardTitle="Daily Updates"
    text="Enjoy fresh content every day with our regularly updated collection of short stories, bedtime stories for preschoolers, and small bedtime stories. You’ll never run out of new and engaging tales to read!"
  ></FeatureCard>

  <FeatureCard
  imageAlt="Easily save your favorite stories, whether it's a bedtime story short or a detailed storybook with pictures. Pick up right where you left off with our intuitive bookmark feature."
    imageSrc="/03.svg"
    cardTitle="Bookmark and Favorites"
    text="Easily save your favorite stories, whether it's a bedtime story short or a detailed storybook with pictures. Pick up right where you left off with our intuitive bookmark feature."
  ></FeatureCard>

  <FeatureCard
  imageAlt="Enjoy a smooth and user-friendly interface that makes navigating through genres, languages, and features like story writing and reading a breeze."
    imageSrc="/06.svg"
    cardTitle="Intuitive User Interface"
    text="Enjoy a smooth and user-friendly interface that makes navigating through genres, languages, and features like story writing and reading a breeze."
  ></FeatureCard>
      </div>

      </div>
      <div className="home-testimonials">
        <div className="home-container09">
          <div className="home-container10">
            <img alt="image" src="/quote-mark.svg" className="home-image3" />
            <h1 className="home-text12 Headline2">
              Valuable Reviews 
            </h1>
            <meta name="keywords" content="
            user reviews for story apps, StoryLine reviews, feedback for story reading apps, best story writing apps reviews, bedtime stories app reviews, multilingual stories app feedback, short stories app reviews
            " />
            <meta name="description" content="
            Discover valuable reviews from StoryLine users about the best story reading and writing app. Learn what users say about bedtime stories, multilingual stories, and short stories apps.
            " />
            <meta property="og:title" content="Valuable Reviews - StoryLine App Feedback" />
            <meta property="og:description" content="
            Hear from our users! StoryLine offers top-rated features for short stories, bedtime stories, story writing, and reading apps. Read what others have to say.
            " />
            <span>5 Starts On Google Play </span>
            <div className="home-container11">
              <TestimonialsCard
                imageSrc="/noun-review-7024860-cropped.svg"
                text="Great App for Story Lovers. Contains quite good and interesting stories."
                text1="Dikshit Sharma"
                text2="Play store User"
              ></TestimonialsCard>
            </div>
          </div>
          <div className="home-container12">
            <div className="home-container13">
              <TestimonialsCard
                src="/logo-1.svg"
                text="The best app for short stories with Quality of story for every mood 👌"
                text1="Yuvraj  Rai"
                text2="Play Store User"
                imageSrc="/noun-review-7024860-cropped.svg"
              ></TestimonialsCard>
            </div>
            <div className="home-container14">
              <TestimonialsCard
                text="Very nice app for shortstories in multiple languages"
                text1="Jh Kr"
                text2="Play Store User"
                imageSrc="/noun-review-7024860-cropped.svg"
              ></TestimonialsCard>
            </div>
          </div>
        </div>
      </div>
      <div className="home-achievements">
        <div className="home-container15">
        <h2 className="home-text14" title="Why Choose StoryLine - The Best Story Reading App">
        Why StoryLine?
        </h2>
        <span className="home-text15">
  <h2>Discover the Ultimate Destination for Story Lovers</h2>
  <p>
    Explore a vast collection of <strong>multilingual stories</strong> across genres like 
    <strong> romance stories </strong>, <strong>mystery stories</strong>, <strong>fantasy</strong>, <strong>horror stories</strong>, <strong>hindi stories</strong>,
    <strong>fun stories</strong>, <strong>sad stories</strong> 
    <strong> bedtime stories</strong>, <strong>short story</strong>, <strong>english small stories</strong>, <strong>english story short</strong>, 
    <strong>short english story</strong>, <strong>short story in english</strong>, <strong>short story of english</strong>, <strong>story for english</strong>, 
    <strong>short english stories</strong>, <strong>short stories in english</strong>, <strong>a short story</strong>, <strong>short stories</strong>, 
    <strong>short story short stories</strong>, <strong>small story of english</strong>, <strong>story of adults</strong>, <strong>stories to read</strong>,
     <strong>story read</strong>, <strong>english story pdf</strong>, <strong>english stories pdf</strong>, <strong>pdf english story</strong>, <strong>stories pdf in english</strong>, 
     <strong>story in english pdf</strong>, <strong>english short story with moral</strong>, <strong>english stories with moral</strong>, <strong>english story with moral</strong>, 
     <strong>moral stories in english</strong>, <strong>short stories in english with moral</strong>, <strong>short stories with moral in english</strong>, 
     <strong>short story in english with moral</strong>, <strong>short story with moral in english</strong>, <strong>small story in english with moral</strong>, 
     <strong>stories in english with moral</strong>, <strong>hindi story</strong>, <strong>story of hindi</strong>, <strong>hindi story in hindi</strong>, <strong>english story</strong>,
      <strong>hindi kahani</strong>, <strong>hindi of story</strong>, <strong>hindi stories in hindi</strong>, <strong>story in hindi</strong>, <strong>in english story</strong>, <strong>in hindi stories</strong>,
       <strong>in hindi story</strong>, <strong>story in english</strong>, <strong>stories for kids</strong>, <strong>story for kids</strong>, <strong>story writing</strong>, <strong>writing stories</strong>, 
       <strong>kahani in hindi</strong>, <strong>hindi kahaniya in hindi</strong>, <strong>hindi short stories in hindi</strong>, <strong>short hindi stories</strong>, <strong>short stories for kids</strong>, 
       <strong>short stories hindi</strong>, <strong>short story hindi</strong>
  </p>
  <br></br>
  <p>
    Whether you're looking for <strong><i>short stories</i></strong>, 
    <strong><i>bedtime stories for kids</i></strong>, or captivating <strong><i>moral stories</i></strong>, 
    StoryLine has something special for every reader.
  </p>
  <br></br>
  <p>
    Start your storytelling journey today!
  </p>
</span>


        <meta name="keywords" content="
        why choose StoryLine, best story reading app, story writing platform, multilingual story app, bedtime stories app, story app for kids, romance and mystery stories, short story app, StoryLine features
        story, wattpad, harry potter and the cursed child, bedtime stories, fable, love stories, bedtime stories for kids, stories for kids, storytelling, english story, story book, a man called ove, 
        short stories for kids, short story in english, hindi story, little red riding hood, yellow wallpaper, motivational stories,
         a thousand splendid suns, mindset book, the kite runner, love story book, english story reading, write a story, google web stories, 
         wattpad stories, english novels, sophie's world, 10 lines short stories with moral, english story book, all free novel, 
         short story in english with moral, romantic novels, moral stories in english, diary book, moral stories, the time traveler's wife, 
         a short story, small story in english, kahani in hindi, fairy tale, snow white and the seven dwarfs, the fault in our stars book,
          short story in hindi, small story, sexy stories, novel book, simple english story, short stories with moral, thousand splendid suns,
           cinderella story, adult stories, 5 lines short stories with moral, wattpad app, romantic stories, 
           lamborghini the man behind the legend, horror story, the secret life of walter mitty, moral stories in hindi, 
           anecdotes, panchatantra, simple short story in english, tamil novel writers, my experiments with truth, fun stories, 
           the wonderful story of henry sugar, the ant and the grasshopper, panchatantra stories in english, forbidden love, 
           love story in hindi, along for the ride book, the haunting of hill house, the fault in our stars john green, 
           small moral stories in english, hot stories, short tales in english, short story short stories, munshi premchand ki kahani, 
           novel full, panchatantra stories, marathi story, the story of my experiments with truth, small stories with moral, 
           hot sex stories, lion and mouse story, moral stories for childrens in hindi, easy small story in english,
            short story in hindi with moral, kannada stories, akbar and birbal short story, akbar birbal, akbar birbal ki kahani,
             akbar birbal short stories, akbar birbal stories, an astrologer's day, arshi wattpad, ashaangi wattpad, atm tamil novels, audio sex stories in hindi, audio sexy story, bacchon ki kahani, bhoot ki kahani, bhoot wali kahani, chudail, chudail ki kahani, class 2 short moral stories in hindi, creepypasta, friendship moral stories in english, gandi kahani, hindi stories for adults, hot sexy story, kabuliwala, kambi novel, kambi stories, library of babel, malayalam short stories, malayalam small story, malayalam stories, mallika manivannan novels, mastram story, meaning of wattpad, moral stories in telugu, moral stories with moral, motivational short stories in hindi, odia story, panchatantra short stories, panchatantra stories in hindi, pariyon ki kahani, peer e kamil, pratilipi malayalam, pratilipi marathi, premchand, punjabi sex stories, rabbit and tortoise story, saaral novels, sad story, sex kahani, short stories in telugu, sidnaaz wattpad, small stories in telugu, stories in telugu, tamil novels, tamil story in tamil, telugu boothu kathalu, telugu kathalu, telugu stories telugu, tenali rama, tenali rama stories, the gift of the magi short story, the thirsty crow, top 10 moral stories in hindi, wattp web, wattpad app web, wattpad meaning, wattpadlogin, william sydney porter
        " />
        <meta name="description" content=" Learn why StoryLine is loved by readers worldwide. Offering a vast collection of multilingual stories, genres like bedtime, romance, and fantasy, it has something for every reader.
         Explore now! story, wattpad, harry potter and the cursed child, bedtime stories, fable, love stories, bedtime stories for kids,
          stories for kids, storytelling, english story, story book, a man called ove, short stories for kids, short story in english, 
          hindi story, little red riding hood, yellow wallpaper, motivational stories, a thousand splendid suns, mindset book, the kite runner,
           love story book, english story reading, write a story, google web stories, wattpad stories, english novels, sophie's world,
            10 lines short stories with moral, english story book, all free novel, short story in english with moral, romantic novels, 
            moral stories in english, diary book, moral stories, the time traveler's wife, a short story, small story in english,
             kahani in hindi, fairy tale, snow white and the seven dwarfs, the fault in our stars book, short story in hindi, 
             small story, sexy stories, novel book, simple english story, short stories with moral, thousand splendid suns, 
             cinderella story, adult stories, 5 lines short stories with moral, wattpad app, romantic stories,
              lamborghini the man behind the legend, horror story, the secret life of walter mitty, moral stories in hindi,
               anecdotes, panchatantra, simple short story in english, tamil novel writers, my experiments with truth, fun stories,
                the wonderful story of henry sugar, the ant and the grasshopper, panchatantra stories in english, forbidden love, 
                love story in hindi, along for the ride book, the haunting of hill house, the fault in our stars john green, 
                small moral stories in english, hot stories, short tales in english, short story short stories, munshi premchand ki kahani,
                 novel full, panchatantra stories, marathi story, the story of my experiments with truth, small stories with moral, 
                 hot sex stories, lion and mouse story, moral stories for childrens in hindi, easy small story in english,
                  short story in hindi with moral, kannada stories, akbar and birbal short story, akbar birbal, akbar birbal ki kahani, 
                  akbar birbal short stories, akbar birbal stories, an astrologer's day, arshi wattpad, ashaangi wattpad, atm tamil novels,
                   audio sex stories in hindi, audio sexy story, bacchon ki kahani, bhoot ki kahani, bhoot wali kahani, chudail,
                    chudail ki kahani, class 2 short moral stories in hindi, creepypasta, friendship moral stories in english,
                     gandi kahani, hindi stories for adults, hot sexy story, kabuliwala, kambi novel, kambi stories, library of babel, 
                     malayalam short stories, malayalam small story, malayalam stories, mallika manivannan novels, mastram story,
                      meaning of wattpad, moral stories in telugu, moral stories with moral, motivational short stories in hindi,
                       odia story, panchatantra short stories, panchatantra stories in hindi, pariyon ki kahani, peer e kamil,
                        pratilipi malayalam, pratilipi marathi, premchand, punjabi sex stories, rabbit and tortoise story, 
                        saaral novels, sad story, sex kahani, short stories in telugu, sidnaaz wattpad, small stories in telugu,
                         stories in telugu, tamil novels, tamil story in tamil, telugu boothu kathalu, telugu kathalu, telugu stories telugu,
                          tenali rama, tenali rama stories, the gift of the magi short story, the thirsty crow, top 10 moral stories in hindi,
                           wattp web, wattpad app web, wattpad meaning, wattpadlogin, william sydney porter " />
        <meta property="og:title" content="Why StoryLine - Best Story App for Readers" />
        <meta property="og:description" content="
        Find out why StoryLine is the best app for story enthusiasts. From bedtime stories to multilingual short stories, there's something for everyone. Start your reading journey today!
        " />
        </div>
        <div className="home-container16">
          <div className="home-container17">
            <StatsCard
            imageAlt="
short story, english small stories, english story short, short english story, short story in english, short story of english, story for english, short english stories, short stories in english, a short story, short stories, short story short stories, small story of english, story of adults, stories to read, story read, english story pdf, english stories pdf, pdf english story, stories pdf in english, story in english pdf, english short story with moral, english stories with moral, english story with moral, moral stories in english, short stories in english with moral, short stories with moral in english, short story in english with moral, short story with moral in english, small story in english with moral, stories in english with moral, hindi story, story of hindi, hindi story in hindi, english story, hindi kahani, hindi of story, hindi stories in hindi, story in hindi, in english story, in hindi stories, in hindi story, story in english, stories for kids, story for kids, story writing, writing stories, kahani in hindi, hindi kahaniya in hindi, hindi short stories in hindi, short hindi stories, short stories for kids, short stories hindi, short story hindi, short story in hindi, stories in hindi short, story in hindi short, writing a story in english, stories in hindi, story story        "
              number="100+"
              imageSrc="/01.svg"
              description="Stories"
            ></StatsCard>
            <StatsCard
            imageAlt=" short story, english small stories, english story short, short english story, short story in english, short story of english, story for english, short english stories, short stories in english, a short story, short stories, short story short stories, small story of english, story of adults, stories to read, story read, english story pdf, english stories pdf, pdf english story, stories pdf in english, story in english pdf, english short story with moral, english stories with moral, english story with moral, moral stories in english, short stories in english with moral, short stories with moral in english, short story in english with moral, short story with moral in english, small story in english with moral, stories in english with moral, hindi story, story of hindi, hindi story in hindi, english story, hindi kahani, hindi of story, hindi stories in hindi, story in hindi, in english story, in hindi stories, in hindi story, story in english, stories for kids, story for kids, story writing, writing stories, kahani in hindi, hindi kahaniya in hindi, hindi short stories in hindi, short hindi stories, short stories for kids, short stories hindi, short story hindi, short story in hindi, stories in hindi short, story in hindi short, writing a story in english, stories in hindi, story story
         Explore now! story, wattpad, harry potter and the cursed child, bedtime stories, fable, love stories, bedtime stories for kids,
          stories for kids, storytelling, english story, story book, a man called ove, short stories for kids, short story in english, 
          hindi story, little red riding hood, yellow wallpaper, motivational stories, a thousand splendid suns, mindset book, the kite runner,
           love story book, english story reading, write a story, google web stories, wattpad stories, english novels, sophie's world,
            10 lines short stories with moral, english story book, all free novel, short story in english with moral, romantic novels, 
            moral stories in english, diary book, moral stories, the time traveler's wife, a short story, small story in english,
             kahani in hindi, fairy tale, snow white and the seven dwarfs, the fault in our stars book, short story in hindi, 
             small story, sexy stories, novel book, simple english story, short stories with moral, thousand splendid suns, 
             cinderella story, adult stories, 5 lines short stories with moral, wattpad app, romantic stories,
              lamborghini the man behind the legend, horror story, the secret life of walter mitty, moral stories in hindi,
               anecdotes, panchatantra, simple short story in english, tamil novel writers, my experiments with truth, fun stories,
                the wonderful story of henry sugar, the ant and the grasshopper, panchatantra stories in english, forbidden love, 
                love story in hindi, along for the ride book, the haunting of hill house, the fault in our stars john green, 
                small moral stories in english, hot stories, short tales in english, short story short stories, munshi premchand ki kahani,
                 novel full, panchatantra stories, marathi story, the story of my experiments with truth, small stories with moral, 
                 hot sex stories, lion and mouse story, moral stories for childrens in hindi, easy small story in english,
                  short story in hindi with moral, kannada stories, akbar and birbal short story, akbar birbal, akbar birbal ki kahani, 
                  akbar birbal short stories, akbar birbal stories, an astrologer's day, arshi wattpad, ashaangi wattpad, atm tamil novels,
                   audio sex stories in hindi, audio sexy story, bacchon ki kahani, bhoot ki kahani, bhoot wali kahani, chudail,
                    chudail ki kahani, class 2 short moral stories in hindi, creepypasta, friendship moral stories in english,
                     gandi kahani, hindi stories for adults, hot sexy story, kabuliwala, kambi novel, kambi stories, library of babel, 
                     malayalam short stories, malayalam small story, malayalam stories, mallika manivannan novels, mastram story,
                      meaning of wattpad, moral stories in telugu, moral stories with moral, motivational short stories in hindi,
                       odia story, panchatantra short stories, panchatantra stories in hindi, pariyon ki kahani, peer e kamil,
                        pratilipi malayalam, pratilipi marathi, premchand, punjabi sex stories, rabbit and tortoise story, 
                        saaral novels, sad story, sex kahani, short stories in telugu, sidnaaz wattpad, small stories in telugu,
                         stories in telugu, tamil novels, tamil story in tamil, telugu boothu kathalu, telugu kathalu, telugu stories telugu,
                          tenali rama, tenali rama stories, the gift of the magi short story, the thirsty crow, top 10 moral stories in hindi,
                           wattp web, wattpad app web, wattpad meaning, wattpadlogin, william sydney porter "
              number="17"
              imageSrc="/04.svg"
              description="Different Languages"
            ></StatsCard>
          </div>
          <div className="home-container18">
            <StatsCard number="10+" description="Genre" imageAlt=" 
         short story, english small stories, english story short, short english story, short story in english, short story of english, story for english, short english stories, short stories in english, a short story, short stories, short story short stories, small story of english, story of adults, stories to read, story read, english story pdf, english stories pdf, pdf english story, stories pdf in english, story in english pdf, english short story with moral, english stories with moral, english story with moral, moral stories in english, short stories in english with moral, short stories with moral in english, short story in english with moral, short story with moral in english, small story in english with moral, stories in english with moral, hindi story, story of hindi, hindi story in hindi, english story, hindi kahani, hindi of story, hindi stories in hindi, story in hindi, in english story, in hindi stories, in hindi story, story in english, stories for kids, story for kids, story writing, writing stories, kahani in hindi, hindi kahaniya in hindi, hindi short stories in hindi, short hindi stories, short stories for kids, short stories hindi, short story hindi, short story in hindi, stories in hindi short, story in hindi short, writing a story in english, stories in hindi, story story ">

            </StatsCard>
            <StatsCard
            imageAlt=" short story, english small stories, english story short, short english story, short story in english, short story of english, story for english, short english stories, short stories in english, a short story, short stories, short story short stories, small story of english, story of adults, stories to read, story read, english story pdf, english stories pdf, pdf english story, stories pdf in english, story in english pdf, english short story with moral, english stories with moral, english story with moral, moral stories in english, short stories in english with moral, short stories with moral in english, short story in english with moral, short story with moral in english, small story in english with moral, stories in english with moral, hindi story, story of hindi, hindi story in hindi, english story, hindi kahani, hindi of story, hindi stories in hindi, story in hindi, in english story, in hindi stories, in hindi story, story in english, stories for kids, story for kids, story writing, writing stories, kahani in hindi, hindi kahaniya in hindi, hindi short stories in hindi, short hindi stories, short stories for kids, short stories hindi, short story hindi, short story in hindi, stories in hindi short, story in hindi short, writing a story in english, stories in hindi, story story "
              number="5"
              imageSrc="/07.svg"
              description="Star on playstore"
            ></StatsCard>
          </div>
        </div>
      </div>
      <div className="home-feature1">
        <div className="home-container19">
          <img
            alt="StoryLine - the best story reading app"
            title='StoryLine - the best story reading app'
            src="/iphonex-1200w.png"
            image_src="/iphonex-1200w.png"
            className="home-image4"
          />
        </div>
        <div className="home-container20">
        <h3 className="home-text16" title="Overview of StoryLine - The Ultimate Storytelling App">
        Overview
        </h3>
        <span className="home-text17">
        Experience StoryLine, the ultimate app for discovering short stories in a variety of languages like Hindi, English, Tamil, Marathi, Telugu, and more. Explore a vast collection of romantic stories, horror stories, love stories in Hindi, and short tales in English that captivate and inspire.

From Hindi stories that touch the heart to Telugu stories that spark imagination, our app offers a world of diverse genres, including romance, mystery, fantasy, adventure, and more. Whether you're looking for an emotional love story in Hindi or a spine-chilling horror story, StoryLine brings the best narratives to life, crossing cultural boundaries and evoking powerful emotions.

Download StoryLine today to enjoy the finest Hindi romantic stories, thrilling horror tales, and engaging short tales in English or stories in Telugu. Start your storytelling journey now and find the perfect story to suit your mood!        </span>

        <meta name="keywords" content="
        StoryLine overview, storytelling app, short stories app, multilingual stories, story app in Hindi, English, Tamil, Marathi, romance stories, mystery stories, fantasy storytelling, adventure stories, cultural storytelling,free novels to read offline, read books for free app, ebook apps, apps for reading books, books for free to read, story writing apps, apps with free books, best app for reading books free, free novel reading apps, novel reading app, wattpad app download, wattpad novels, good apps to read books for free, reading book online for free, story reading app, apps to read books on for free, write apps, book apps with free books, best app for reading books for free, best app for books reading, books to read on online, ebook application, wattpad apps, book stories to read, book reading free app, apps for free book reading, stories books to read, novels apps, novels reading, wattpad stories romance, books library app, apps for book readers, novel book reading, reading story app, romance books on wattpad, stories on wattpad, wattpad book
        " />
        <meta name="description" content="
        Discover StoryLine, the app for storytelling enthusiasts. Explore multilingual short stories across genres like romance, mystery, fantasy, and adventure. Available in languages such as Hindi, English, Tamil, and more.
        " />
        <meta property="og:title" content="Overview of StoryLine - Multilingual Storytelling App" />
        <meta property="og:description" content="
        StoryLine offers a rich collection of short stories across languages and genres. From Hindi and English to Tamil and Marathi, find stories that transcend cultures and evoke emotions.
        " />
          <a
            href="https://play.google.com/store/apps/details?id=com.storyline.storytelling"
            target="_blank"
            rel="noreferrer noopener"
            className="home-link4"
          >
            <LinkIconButton
              text="Get Started"
              className="home-component16"
            ></LinkIconButton>
          </a>
        </div>
      </div>
      <div className="home-feature2">
      <div className="home-container21">
      <h2 className="Headline2 home-text18" title="Key Features of StoryLine - Best Story Reading App">
        Key Features of StoryLine
      </h2>
      <span className="home-text19">
        StoryLine offers an intuitive and user-friendly interface tailored for seamless navigation and personalized reading experiences. Enjoy features like offline reading, multilingual stories (Hindi, English, Tamil, Marathi, and more), community engagement through story sharing, and customized story recommendations based on your preferences. It is the perfect app for short stories, bedtime stories, and creative storytelling enthusiasts worldwide, fostering a vibrant global community of readers and writers.
      </span>

      <meta name="keywords" content="
          StoryLine features, storytelling app features, offline reading app, personalized story app, community storytelling app, multilingual short stories, short story app, Hindi story app, bedtime stories app, creative storytelling platform
      " />
      <meta name="description" content="
          Discover the top features of StoryLine, a storytelling app with offline reading, multilingual stories, personalized recommendations, and community engagement. Read stories in Hindi, English, Tamil, and more.
      " />
      <meta property="og:title" content="Key Features of StoryLine - Best Short Story App" />
      <meta property="og:description" content="
          Explore StoryLine, the best storytelling app for offline reading, personalized recommendations, and community storytelling. Available in Hindi, English, and other languages.
      " />
      </div>

      <img
      alt="StoryLine - the best story writing app"
      title='StoryLine - the best story writing app'
      src="/Untitled design.png?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&h=1000"
      image_src="/Untitled design.png"
      className="home-image5"
      />

      </div>
      <div className="home-cta">
        <div className="home-container22">
          <div className="home-container23">
          <h2 className="home-text20" title="Embark on Your Storytelling Journey with StoryLine">
            Embark on Your Storytelling Journey
          </h2>
          <span className="home-text21">
            Download StoryLine, the ultimate app for storytelling enthusiasts, and dive into a rich tapestry of short stories, bedtime stories, and creative narratives across multiple languages like Hindi, English, Tamil, and more. Enjoy personalized reading experiences with recommendations tailored to your preferences, and explore a wide range of genres including romance, mystery, fantasy, and adventure. Connect with captivating narratives from around the world and discover your next favorite story today.
          </span>

          <meta name="keywords" content="
              storytelling journey, StoryLine app, multilingual stories app, short story app, bedtime stories, story reading app, personalized reading experience, Hindi stories, English stories, Tamil stories, story app for kids, creative storytelling app, romance stories, mystery stories, fantasy stories, adventure stories
          " />
          <meta name="description" content="
              Start your storytelling journey with StoryLine! Download the app to enjoy a wide range of short stories in Hindi, English, Tamil, and more. Personalized recommendations and diverse genres await.
          " />
          <meta property="og:title" content="Embark on Your Storytelling Journey with StoryLine - Download Now" />
          <meta property="og:description" content="
              Download StoryLine to explore a rich collection of short stories, personalized recommendations, and multilingual content in Hindi, English, Tamil, and more. Perfect for readers of all ages!
          " />
            <span className="home-text22 Subtitle2">
              <span className="home-text23">Get the App</span>
            </span>
            <a
              href="https://play.google.com/store/apps/details?id=com.storyline.storytelling"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link5"
            >
              <StoreBanner className="home-component17"></StoreBanner>
            </a>
          </div>
          <div className="home-container24">
            <img
              alt="StoryLine - App for stories"
              title='StoryLine - App for stories'
              src="/iphonex-1200w.png"
              image_src="/iphonex-1200w.png"
              className="home-image6"
            />
            <img
              alt="Storyline - Best App for stories"
              title='Storyline - Best App for stories'
              src="/iphonex-1200w.png"
              image_src="/iphonex-1200w.png"
              className="home-image7"
            />
          </div>
        </div>
      </div>
      <div className="home-achievements">
  <div className="home-container15">
    <h2 className="home-text14" title="Why Choose StoryLine - The Best Story Reading App">
    </h2>
    <span className="home-text15">
      <h2>Frequently Asked Questions</h2>
      <br></br>
      <div class="faq-item">
            <h3>1. What types of stories can I find on StoryLine?</h3>
            <p>StoryLine offers a wide variety of stories, including:</p>
            <p>
                <span>Short stories</span>, <span>Love stories</span>, <span>Bedtime stories for kids</span>, 
                <span>Fables</span>, <span>Fairy tales</span>, <span>Cinderella story</span>, 
                <span>Motivational stories</span>, <span>Mindset books</span>, <span>Horror stories</span>, 
                <span>Forbidden love stories</span>, <span>Romantic stories</span>.
            </p>
        </div>
        <br></br>

        <div class="faq-item">
            <h3>2. Can I read stories in different languages?</h3>
            <p>Yes! StoryLine offers stories in multiple languages, including:</p>
            <p>
                <span>Hindi stories</span>, <span>Marathi stories</span>, <span>Malayalam short stories</span>, 
                <span>Telugu stories</span>, <span>Odia stories</span>.
            </p>
        </div>
        <br></br>

        <div class="faq-item">
            <h3>3. Are there short stories for kids available?</h3>
            <p>Yes, we have a dedicated section for kids, including:</p>
            <p>
                <span>Bedtime stories for kids</span>, <span>Short stories for kids</span>, 
                <span>Simple short stories in English</span>, <span>Small moral stories in English</span>, 
                <span>Bacchon ki kahani</span>.
            </p>
        </div>
        <br></br>

        <div class="faq-item">
            <h3>4. Can I find love stories on StoryLine?</h3>
            <p>Yes, we have a collection of <span>love story books</span> including:</p>
            <p>
                <span>Romantic stories</span>, <span>Love story in Hindi</span>, <span>Short love stories</span>.
            </p>
        </div>
        <br></br>

        <div class="faq-item">
            <h3>5. Can I read short stories in English?</h3>
            <p>Yes, StoryLine has a vast collection of <span>English stories</span> such as:</p>
            <p>
                <span>Short story in English with moral</span>, <span>Small story in English</span>, 
                <span>Simple English story</span>, <span>English story books</span>, <span>English novels</span>, 
                <span>Short stories in English with moral lessons</span>.
            </p>
        </div>
        <br></br>

        <div class="faq-item">
            <h3>6. Are there any moral stories available?</h3>
            <p>Yes, StoryLine features a large collection of <span>moral stories</span> in both English and Hindi, including:</p>
            <p>
                <span>Moral stories in English</span>, <span>Small stories with moral</span>, 
                <span>Moral stories for children in Hindi</span>, <span>Moral stories in Hindi</span>, 
                <span>Friendship moral stories in English</span>.
            </p>
        </div>
        <br></br>

        <div class="faq-item">
            <h3>7. Can I read horror or sad stories?</h3>
            <p>Yes, we offer a collection of <span>horror stories</span> and <span>sad stories</span> including:</p>
            <p>
                <span>Horror stories</span> like <span>bhoot ki kahani</span>, <span>sad story collection</span>.
            </p>
        </div>
        <br></br>

        <div class="faq-item">
            <h3>8. Can I contribute my own stories to StoryLine?</h3>
            <p>Yes, you can write and submit your own <span>stories</span> on StoryLine. You can write a <span>short story</span> or even contribute to our <span>English story reading</span> community.</p>
        </div>
    </span>
    
    <meta name="keywords" content="
      why choose StoryLine, best story reading app, story writing platform, multilingual story app, bedtime stories app, story app for kids, romance and mystery stories, short story app, StoryLine features
    " />
    <meta name="description" content="
      Learn why StoryLine is loved by readers worldwide. Offering a vast collection of multilingual stories, genres like bedtime, romance, and fantasy, it has something for every reader. Explore now!
    " />
    <meta property="og:title" content="Why StoryLine - Best Story App for Readers" />
    <meta property="og:description" content="
      Find out why StoryLine is the best app for story enthusiasts. From bedtime stories to multilingual short stories, there's something for everyone. Start your reading journey today!
    " />
  </div>
</div>

      <div className="home-footer">
        <footer className="home-container25">
          <img alt="image" src="/21.svg" className="home-image8" />
          <div className="home-container26">
            <span className="home-text24">
              Contact Us :- storylineofficials@gmail.com
            </span>
          </div>
          <div className="home-divider"></div>
          <div className="home-container27">
            <span className="home-text25">
              © 2024 Storyline. All rights reserved
            </span>
            <div className="home-container28">
              <a
                href="https://www.instagram.com/storylineofficals/"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link6"
              >
                <div className="home-container29">
                  <svg
                    viewBox="0 0 877.7142857142857 1024"
                    className="home-icon13"
                  >
                    <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
                  </svg>
                </div>
              </a>
              <a
                href="https://twitter.com"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link7"
              >
                <div className="home-container30">
                  <svg
                    viewBox="0 0 950.8571428571428 1024"
                    className="home-icon15"
                  >
                    <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                  </svg>
                </div>
              </a>
              <a
                href="https://www.youtube.com/@StoryLine_Diverse"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link8"
              >
                <div className="home-container31">
                  <svg viewBox="0 0 1024 1024" className="home-icon17">
                    <path d="M406.286 644.571l276.571-142.857-276.571-144.571v287.429zM512 152c215.429 0 358.286 10.286 358.286 10.286 20 2.286 64 2.286 102.857 43.429 0 0 31.429 30.857 40.571 101.714 10.857 82.857 10.286 165.714 10.286 165.714v77.714s0.571 82.857-10.286 165.714c-9.143 70.286-40.571 101.714-40.571 101.714-38.857 40.571-82.857 40.571-102.857 42.857 0 0-142.857 10.857-358.286 10.857v0c-266.286-2.286-348-10.286-348-10.286-22.857-4-74.286-2.857-113.143-43.429 0 0-31.429-31.429-40.571-101.714-10.857-82.857-10.286-165.714-10.286-165.714v-77.714s-0.571-82.857 10.286-165.714c9.143-70.857 40.571-101.714 40.571-101.714 38.857-41.143 82.857-41.143 102.857-43.429 0 0 142.857-10.286 358.286-10.286v0z"></path>
                  </svg>
                </div>
              </a>
            </div>
          </div>
        </footer>
      </div>
    </div>
  )
}

export default Home
